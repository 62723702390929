const MetaLogin = () => {
    return <>
        <a href={"https://www.facebook.com/v21.0/dialog/oauth" +
            "?client_id=1087075489495810" +
            "&redirect_uri=http://localhost:8080/meta/login/callback" +
            "&scope=ads_management,ads_read,read_insights"}>
            <button style={{ margin: "10px", padding: "10px", fontSize: "16px" }}>
                meta 로그인
            </button>
        </a>
    </>
}

export default MetaLogin;