import { Button } from "antd";
import { getAdAccounts } from "../../axios/MetaBusiness";
import MetaLogin from "./login";
import { useState } from "react";

const Meta = () => {
    const [data, setData] = useState<string>();
    const getAdAccountsButtonOnClick = () => {
        getAdAccounts()
            .then(res => {
                setData(res.data.body)
                console.log(res.data.body)
            })
            .catch(err => console.error(err))
    }

    return <>
        <div >
            <div>Meta</div>
            <MetaLogin />
            <br />
            <Button onClick={getAdAccountsButtonOnClick}>광고 정보 가져오기</Button>
            <br />
            <br />
            <div>
                {data}
            </div>
        </div>
    </>
}
export default Meta;