import { getAllCustomerLink } from "../../../axios/NaverSearchad";
import { Button } from "antd";

const NaverSearchad = () => {

    return <>
        <Button type='primary' onClick={() => getAllCustomerLink().then(res => console.log(res.data))} children='요청' />
    </>

}

export default NaverSearchad;