import { ConfigProvider } from 'antd';
import './App.css';
import ScrollTop from './components/ScrollTop';
import Routes from './routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import locale from 'antd/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
dayjs.locale('ko');

function App() {
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            font-size: 14px;
          }
        `,
      },
    },
  });

  return (<>
    <QueryClientProvider client={new QueryClient()}>
      <ThemeProvider theme={theme}>
        <ScrollTop>
          <ConfigProvider locale={locale}>
            <Routes />
          </ConfigProvider>
        </ScrollTop>
      </ThemeProvider>
    </QueryClientProvider>
  </>);
}

export default App;
